<template>
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="ghost" class="flex items-center gap-2">
        <span v-if="user">{{ user.name }}</span>
        <span v-else>Loading...</span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent>
      <DropdownMenuItem @click="router.push('/profile')">
        Profile
      </DropdownMenuItem>
      <DropdownMenuItem v-if="isAdmin" @click="router.push('/admin')">
        Admin
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <LogoutButton />
    </DropdownMenuContent>
  </DropdownMenu>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { Button } from '@/components/ui/button';
import { useRouter } from 'vue-router';
import { 
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator
} from '@/components/ui/dropdown-menu';
import LogoutButton from './LogoutButton.vue';
import { computed } from 'vue';

const { user } = useAuth0();
const router = useRouter();

const isAdmin = computed(() => {
  return user.value?.['n0tlikethat.dev/roles']?.includes('admin');
});
</script>
