import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { authGuard } from '@auth0/auth0-vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'UnauthHome',
        component: () => import('@/home/UnauthHome.vue'),
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/home/Home.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/programs',
        name: 'Programs',
        component: () => import('@/programs/Programs.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/programs/:programId',
        name: 'ProgramView',
        component: () => import('@/programs/ProgramView.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/scans',
        name: 'Scans',
        component: () => import('@/scans/Scans.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/scan-configs',
        name: 'ScanConfigs',
        component: () => import('@/scans/ScanConfigs.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('@/admin/AdminPage.vue'),
        beforeEnter: authGuard,
        meta: {
            auth: ["admin"]
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/profile/Profile.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/auth-error',
        name: 'AuthError',
        component: () => import('@/auth/AuthError.vue'),
    },
    {
        path: '/callback',
        name: 'Callback',
        component: () => import('@/auth/Callback.vue'),
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: () => import('@/onboarding/Onboarding.vue'),
        beforeEnter: authGuard
    },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.onError((error) => {
    console.error('Router error:', error);
});

export default router;
