import { createAuth0 } from '@auth0/auth0-vue';
import { BASE_URL } from '@/utils/debug';

let DOMAIN = "login-dev.n0tlikethat.dev"

export const auth0 = createAuth0({
    domain: DOMAIN,
    clientId: 'F9yZlnesRyoDKbX1X15gu0YCI5a4RAVP',
    authorizationParams: {
      redirect_uri: BASE_URL + '/callback',
      audience: "https://api-dev.n0tlikethat.dev" // TODO dev or prod
    }
  })