<template>
  <Button @click="doLogout">Logout</Button>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { Button } from '@/components/ui/button';
const { logout } = useAuth0();

const doLogout = () => {
  logout({ logoutParams: { returnTo: window.location.origin } });
};
</script>
