<template>
    <div class="min-h-screen">
        <header class="border-b">
            <div class="flex h-16 items-center px-4">
                <h1 class="text-xl font-semibold">PI Automation</h1>
                <div class="flex-1" />
                <button class="md:hidden" @click="drawer = !drawer">
                    <MenuIcon class="h-6 w-6" />
                </button>
                <div class="hidden md:flex items-center gap-4">
                    <RouterLink v-for="item in menuItems" 
                        :key="item.title" 
                        :to="item.to"
                        custom
                        v-slot="{ navigate }">
                        <Button 
                            :variant="route.path === item.to ? 'default' : 'ghost'"
                            @click="navigate">
                            {{ item.title }}
                        </Button>
                    </RouterLink>
                    <ProfileButton v-if="isAuthenticated" />
                    <LoginButton v-else />
                </div>
            </div>
        </header>

        <Sheet v-model:open="drawer">
            <SheetContent side="left">
                <nav class="flex flex-col gap-4">
                    <RouterLink v-for="item in menuItems" 
                        :key="item.title" 
                        :to="item.to"
                        custom
                        v-slot="{ navigate }">
                        <Button 
                            :variant="route.path === item.to ? 'default' : 'ghost'"
                            @click="() => {
                                navigate();
                                drawer = false;
                            }">
                            {{ item.title }}
                        </Button>
                    </RouterLink>
                    <ProfileButton v-if="isAuthenticated" />
                    <LoginButton v-else />
                </nav>
            </SheetContent>
        </Sheet>

        <main class="container mx-auto p-4">
            <Toaster />
            <RouterView />
        </main>
    </div>
</template>

<script setup lang="ts">
import { RouterView, useRoute, RouterLink } from 'vue-router';
import { ref } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { MenuIcon } from 'lucide-vue-next';
import { Button } from '@/components/ui/button';
import Sheet from '@/components/ui/sheet/Sheet.vue';
import SheetContent from '@/components/ui/sheet/SheetContent.vue';
import Toaster from '@/components/ui/toast/Toaster.vue';
import ProfileButton from '@/profile/ProfileButton.vue';
import LoginButton from '@/profile/LoginButton.vue';
import { useColorMode } from '@vueuse/core';

const drawer = ref(false);

const route = useRoute();

const mode = useColorMode();

const { isAuthenticated } = useAuth0();

const menuItems = ref([
    {
        title: 'Home',
        to: '/home',
    },
    {
        title: 'Programs',
        to: '/programs',
    },
    {
        title: 'Scans',
        to: '/scans',
    },
    {
        title: 'Scan Configs',
        to: '/scan-configs',
    },
]);
</script>