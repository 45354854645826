<template>
  <Button @click="doLogin">Login</Button>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { Button } from '@/components/ui/button';
const { loginWithRedirect } = useAuth0();

const doLogin = () => {
  loginWithRedirect();
};
</script>
