import '@/css/main.css';

import { createApp } from 'vue';

import { createPinia } from 'pinia'
//import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue';
import router from './router';
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query';
// import { persister } from '@/plugins/persister';
// import { persistQueryClient } from '@tanstack/query-persist-client-core';
import { auth0 } from '@/utils/auth0';

import * as Sentry from '@sentry/vue';

import { LOCAL } from '@/utils/debug';

const app = createApp(App);

if (!LOCAL) {
	Sentry.init({
		app,
		dsn: "https://2058385f9669fbbcdf56fcd7c1fa0ce0@o4508418747662336.ingest.us.sentry.io/4508418749562880",
		integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["panel-dev.n0tlikethat.dev", "localhost"],
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

app.use(router);

app.use(auth0);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 5, // 5 minutes
		},
	},
});
  
// Set up persistence
// persistQueryClient({
// 	queryClient,
// 	persister,
// 	maxAge: 1000 * 60 * 60 * 24, // 24 hours
// });
  
// Use VueQueryPlugin with the configured queryClient
app.use(VueQueryPlugin, {
	queryClient,
});

const pinia = createPinia()
//pinia.use(piniaPluginPersistedstate)
app.use(pinia)

app.config.errorHandler = (error, instance, info) => {
	console.error('App error:', error);
	console.error('App instance:', instance);
	console.error('Extra info:', info);
};

app.mount('#app');